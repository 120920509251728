import * as React from "react"
import Layout from "../layout"

export default function SuccessPage() {
  return (
    <Layout>
      <div style={{ height: 500, margin: "64px", padding: 32 }}>
        <h1>Tack, vi hörs!</h1>
        <h3>
          Vi återkommer till dig inom kort för att bekräfta din fråga eller
          beställning. Med önskan om gott samarbete, hälsar vi dig välkommen
          till oss på Albins Trafikskola!
        </h3>
        <p>
          <a href="https://www.facebook.com/albinstrafik/" className="fbLink">
            <img
              src={require("./../images/FB.png")}
              alt="facebook"
              width="42"
            />
          </a>
          Gilla oss på Facebook för att få bra erbjudanden, tips om lediga
          körlektioner och annan viktig information!
        </p>
      </div>
    </Layout>
  )
}
